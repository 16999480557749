define("stickymap", function(require) {
    'use strict';

    $(function() {
        $('.tabs-navi > div').click(function (){
            $(this).parents('.tabs-navi').find('.tabs-nav').removeClass('active');
            $(this).addClass('active');
            let id = $(this).attr('data-id');
            $(this).parents('.tabs').find('.tabs-element').each(function(){
                if($(this).attr('data-id') == id) {
                    $(this).addClass('active');
                } else {
                    $(this).removeClass('active');
                }
            })
        });
    });

});