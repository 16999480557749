define("yacht-grid", function(require) {
    'use strict';

    //var $ = require('jquery');
    require('../../grids');
    
    /**
     * Sets up the module
     * @param settings The settings object from the module displaying the grid
     */
    var setup = function(settings) {
        setupEqualHeightGrid(settings);
    };
    
    
    /**
     * Initializes the normalization of the grid items
     * @param settings The settings object from the module displaying the grid
     */
    var setupEqualHeightGrid = function(settings) {
        var normalizeSearchResultHeight = function () {
            $(settings.selector.totalPrice).responsiveEqualHeightGrid();
            //$('.yachtcharter-search-result').parent().responsiveEqualHeightGrid();
        };
        normalizeSearchResultHeight();
        var $priceDetails = $(settings.selector.priceDetails);
    
        // Set the height of the price details box as well as the search result tile to auto height during the expanding animation
        $priceDetails.on('show.bs.collapse', function () {
            $(this).parents(settings.selector.totalPriceElement).css('height', 'auto');
            //$(this).parents('.yachtcharter-search-result').parent().css('height', 'auto');
        });
        // After the price details have been fully expanded, re-normalize the heights of the items
        // Note the different event names ("showN" vs. "show")
        $priceDetails.on('shown.bs.collapse hidden.bs.collapse', normalizeSearchResultHeight);
    };
    
    
    return {
        setup: setup
    };

});


