define("wishlist", function(require) {
    'use strict';
    
    //var $ = require('jquery');

    //var PNotify = require('pnotify');
    //PNotify.prototype.options.styling = 'bootstrap3';

    //var addYachtToEnquiry = require('../modules/effects/addYachtToEnquiry');
    var yachtGrid = require('../modules/components/yacht-grid');
    var stickySidebar = require('../modules/components/sticky-sidebar');


    var settings = {
        selector: {
            stickySidebarContainer: '.module-wishlist .wishlist-sidebar',
            moduleRoot: '.module-wishlist',
            includedServices: '.module-wishlist .yachtcharter-search-result .included-services-detail-link',
            priceDetails: '.module-wishlist .price-details',
            //addYachtToEnquiry: '.yachtcharter-search-result .add-yacht-to-enquiry',
            searchResult: '.yachtcharter-search-result',
            totalPrice: '.yachtcharter-search-result .total-price',
            totalPriceElement: '.total-price',
            removeYachtFromWishlist: '.add-to-wishlist.added',
            confirmRemoveYachtFromWishlist: '.confirm-remove-yacht-from-wishlist',
            restoreYachtToWishlist: '.restore-yacht-to-wishlist'
        },
        stickySidebarMinScreenWidth: 992
    };

    var setupInteraction = function() {
        // show popover with the complete included services when hovering the first line on a yacht tile
        $(settings.selector.includedServices).each(function() {
            $(this).popover({
                content: $(this).closest('.yachtcharter-search-result').find('.included-services-detail').html(),
                placement: 'top',
                html: true,
                trigger: 'hover'
            });
        });

        // setup event handlers for removing a yacht from the wishlist
        $(settings.selector.moduleRoot).on('click', settings.selector.removeYachtFromWishlist, removeYachtFromWishlist);
        $(settings.selector.moduleRoot).on('click', settings.selector.confirmRemoveYachtFromWishlist, confirmRemoveYachtFromWishlist);
        $(settings.selector.moduleRoot).on('click', settings.selector.restoreYachtToWishlist, restoreYachtToWishlist);
    };


    /**
     * Fades out the tile and offers the possibility to restore it
     */
    var removeYachtFromWishlist = function() {
        var $toggleWishlistBtn = $(this);
        var $searchResult = $toggleWishlistBtn.parents(settings.selector.searchResult);

        $searchResult.fadeOut('slow', function () {
            $searchResult.css({
                display: 'block',
                visibility: 'hidden'
            });

            var confirmationMessage = '„' + $searchResult.find('.yacht-title').html() + '“ wurde von der Merkliste enfernt.';

            $searchResult.before(
                '<div class="removed-from-wishlist text-center">' +
                    '<h3>' + confirmationMessage + '</h3>' +
                    '<br><br>' +
                    '<button type="button" class="btn btn-block btn-default text-geometric text-uppercase btn-lg confirm-remove-yacht-from-wishlist">Ausblenden</button><br>' +
                    '<button type="button" class="btn btn-block btn-success text-geometric text-uppercase btn-lg restore-yacht-to-wishlist">Rückgängig machen</button>' +
                '</div>'
            );
        });
    };

    /**
     * Permanently removes a yacht from the wishlist
     */
    var confirmRemoveYachtFromWishlist = function() {
        // TODO communicate with server
        var $confirmBtn = $(this);
        var $searchResult = $confirmBtn.parents('.removed-from-wishlist').siblings(settings.selector.searchResult);

        $searchResult.parent().fadeOut();

    };

    /**
     * Restores a yacht after it has been accidentally removed from the wishlist
     */
    var restoreYachtToWishlist = function() {
        // TODO communicate with server
        var $restoreBtn = $(this);
        var $removedFromWishlist = $restoreBtn.parents('.removed-from-wishlist');
        var $searchResult = $removedFromWishlist.siblings(settings.selector.searchResult);

        $removedFromWishlist.hide();
        $searchResult.css({visibility: 'visible'});
    };

    $(function() {
        setupInteraction();
        //addYachtToEnquiry.setup(settings);
        yachtGrid.setup(settings);
        stickySidebar.setup(settings);
    });
});