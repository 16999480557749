define(function(require) {
    'use strict';

    require('../modules/components/tabs');

    require('jquery-smooth-scroll');

    var setupSmoothScrolling = function(){
        $('.scroll-to-form').smoothScroll({
            offset: -100,
            speed: 1500,
        });
    };

    $(function() {
        setupSmoothScrolling();
    });
});