define("enquiry", function(require) {
    'use strict';

    //var $ = require('jquery');

    var settings = {
        selector: {
            messageTextArea: '.module-enquiry #CustomerCharterRequestForm_message',
            messagePopoverTitle: '#message-popover-title',
            messagePopoverText: '#message-popover-text',
            alternativeTimeFrame: '#custom-alternative-time-frame',
            toggleCustomAlternativeTimeFrame: '#enable-custom-alternative-time-frame',

            previousAndFollowingWeekToggles: '#allow-previous-week, #allow-following-week',
            previousAndFollowingWeekLabels: '#allow-previous-week-display, #allow-following-week-display'
        }
    };

    $(function () {
        $(settings.selector.messageTextArea)
            .popover({
                title: $(settings.selector.messagePopoverTitle).html(),
                content: $(settings.selector.messagePopoverText).html(),
                placement: 'top',
                trigger: 'focus',
                html: true
            });

        $(settings.selector.previousAndFollowingWeekToggles).change(function () {
            var $label = $($(this).data('label'));
            if($(this).prop('checked')) {
                $label.removeClass('hide');
            } else {
                $label.addClass('hide');
            }
        });

        $(settings.selector.previousAndFollowingWeekLabels).click(function () {
            var $label = $(this);
            $label.toggleClass('hide');
            var $field = $($label.data('field'));
            if($label.hasClass('hide')) {
                $field.attr('checked', false);
            } else {
                $field.attr('checked', true);
            }
        });

        $(settings.selector.toggleCustomAlternativeTimeFrame).change(function () {
            var $alternativeTimeFrameContainer = $(settings.selector.alternativeTimeFrame);
            if(false === $(this).prop('checked')) {
                $alternativeTimeFrameContainer.find('input').val('');
            }
            $alternativeTimeFrameContainer.collapse('toggle')
        });
    });
});