define("details", function(require) {
    'use strict';

    //var $ = require('jquery');

    // require('bootstrap/tab');
    // require('bootstrap/affix');
    // require('bootstrap/scrollspy');
    // require('bootstrap/carousel');
    // require('bootstrap/collapse');
    // require('bootstrap/transition');

     require('jquery-smooth-scroll');


    // var addYachtToEnquiry = require('../modules/effects/addYachtToEnquiry');
    var stickySidebar = require('../modules/components/sticky-sidebar');

    var addYachtToWishlist = require('../modules/effects/addYachtToWishlist');

    var settings = {
        selector: {
            stickySidebarContainer: '.module-yacht-details .cta-box',
            //addYachtToEnquiry: '.add-yacht-to-enquiry',
            searchResult: '.cta-box .panel-body.total-price',
            addYachtToWishlist: '.module-yacht-details .add-to-wishlist',
        },
        stickySidebarMinScreenWidth: 992
    };

    var setupSmoothScrolling = function(){
        $('a').smoothScroll({exclude: ['.no-smooth-scroll'], excludeWithin: ['.yachtcharter-search-result']});
    };

    $(function() {
        setupSmoothScrolling();
        stickySidebar.setup(settings);

        addYachtToWishlist.setup(settings);
        // addYachtToEnquiry.setup(settings);
    });
});