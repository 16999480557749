define("sticky-sidebar", function(require) {
    'use strict';

    var globalSettings = require('../../../js/global-settings');

    function changeSidebarWidth() {
        let container =  $('.sticky-sidebar-container');
        let containerWidth =  container.outerWidth();
        let element = container.find('.sticky-sidebar');

        if(!container.length) {
            return;
        }
        element.css('width', containerWidth);
    }

    $(document).ready(function() {
        changeSidebarWidth();
    });

    $(window).resize(function(){
        changeSidebarWidth();
    });


    var setup = function(settings) {
        var $sidebarContainer = $(settings.selector.stickySidebarContainer);

        if($sidebarContainer.length) {
            var offsetTop = $sidebarContainer.css('position', 'static').offset().top;
            $sidebarContainer.affix({
                offset: {
                    //top: 472,
                    top: function () {
                        var windowWideEnough = $(window).width() > settings.stickySidebarMinScreenWidth;
                        var windowHighEnough = ($(window).height() - globalSettings.menuBarHeightPixels - 40) >= $sidebarContainer.height();
                        if(windowWideEnough && windowHighEnough) {
                            $sidebarContainer.css('position', '');

                            return offsetTop - globalSettings.menuBarHeightPixels - 40;
                        } else {
                            $sidebarContainer.removeClass('affix');
                            return -1;
                        }
                    },
                    bottom: function() {
                        return $('.footer').outerHeight() + 30;
                    }
                }
            }).on('affix.bs.affix', function () { // before affix
                $(this).css({
                    /*'top': headerHeight,*/    // for fixed height
                    'width': $(this).outerWidth()  // variable widths
                });
            });
        }
    };

    return {
        setup: setup
    }
});