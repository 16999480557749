define("wish-list", function(require) {
    'use strict';
    //var $ = require('jquery');

    var settings = {
        selector: {}
    };

    /**
     * Sets up the module.
     * @param passedSettings The settings object from the calling module.
     * Requires the following fields:
     * - passedSettings.selector.searchResult: the buttons parent matching this selector will be cloned and animated to the wishlist button in the navbar.
     *
     */
    var setup = function(passedSettings) {
        settings = passedSettings;
        $(document).off('click', settings.selector.addYachtToWishlist, onClickButtonAddYachtToWishlist)
        $(document).on('click', settings.selector.addYachtToWishlist, onClickButtonAddYachtToWishlist)
    };

    /**
     * Event handler method which gets called when the "add to wishlist" button is clicked.
     *
     * - Animates the button
     * - updates the counter in the navbar's wishlist button
     */
    var onClickButtonAddYachtToWishlist = function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $addToWishlistButton = $(this);

        if(false === $addToWishlistButton.hasClass('added')) {
            animateButton($addToWishlistButton)
        }

        $.ajax({
            url : $addToWishlistButton.data('href'),
            type : 'POST',
            dataType : 'json',
            cache : false,
        }).done(function (data) {
            var $badge = $('#auxiliary-menu-wishlist .badge');
            $badge.html(data.newCount);
            $addToWishlistButton.toggleClass('added', (data.status === 'added'))
            $addToWishlistButton.blur()
        });
    };

    var animateButton = function ($addToWishlistButton) {
        var buttonOffset = $addToWishlistButton.offset();
        var targetOffset = $('#auxiliary-menu-wishlist .fa-heart').offset();
        var documentWidth = $(document).width();


        var $buttonClone = $('<div class="add-to-wishlist added added-clone"><i class="fa fa-heart"></i></div>')

        var $icon = $buttonClone.children().first();
        $icon.css({
            textShadow: '0 0 0px',
            opacity: 1,
        });
        $('body').append($buttonClone);


        var width = $icon.width();

        window.setTimeout(function () {
            $icon.css({
                right: documentWidth - buttonOffset.left - width,
                top: buttonOffset.top,
                transform: 'scale(10)',
                textShadow: '0 0 10px',
            })
        }, 10);

        window.setTimeout(function () {
            $icon.css({
                right: documentWidth - targetOffset.left - width,
                top: targetOffset.top,
                transform: 'scale(1)',
                textShadow: '0 0 0px',
                opacity: 0,
            })
            window.setTimeout(function () {
                $icon.remove()
            }, 600);
        }, 600);
    };


    return {
        setup: setup
    }
});