define('mediacenter', function(require) {
    'use strict';

    require('jquery-smooth-scroll');

    var stickySidebar = require('../modules/components/sticky-sidebar');


    var settings = {
        selector: {
            stickySidebarContainer: '.module-press-releases .sticky-sidebar',
        },
        stickySidebarMinScreenWidth: 992
    };

    var setupSmoothScrolling = function(){
        $('a').smoothScroll({exclude: ['.no-smooth-scroll'], excludeWithin: ['.yachtcharter-search-result']});
    };


    $(function() {
        stickySidebar.setup(settings);
        setupSmoothScrolling();
    });

});