/**
 * Creates the sticky map on the side of a destination landing page
 *
 */

define("stickymap", function(require) {
    'use strict';

     //var $ = require('jquery');
    // require('bootstrap/affix');

    var settings = {
        selector: {
            map: '#destination-map'
        }
    };
    var $map;
    var stickyMapMinScreenWidth = 992;
    var menuBarHeight = 65;

    var resizeMap = function() {
        if($map.length) {
            if($(window).width() > stickyMapMinScreenWidth) {
                var oldPositionType = $map.css('position');
                // First position the map statically with 100% width and determine the resulting width.

                $map.css({width: '100%'});

                // Then position it however it was positioned before and assign the calculated width and available height as fixed dimensions.
                $map.css({
                    height: $(window).height() - menuBarHeight,
                    width: $map.width(),
                    position: oldPositionType
                });
            } else {
                $map.css({
                    height: '',
                    width: ''
                });
            }
        }
    };

    $(function(){
        $map = $(settings.selector.map);

        if($map.length) {
            resizeMap();
            $(window).resize(resizeMap);
            var offsetTop = $map.css('position', 'static').offset().top;
            $map.affix({
                offset: {
                    top: function () {
                        if($(window).width() > stickyMapMinScreenWidth) {
                            $map.css('position', '');
                            return offsetTop - menuBarHeight;
                        } else {
                            return -1;
                        }
                    },
                    bottom: function() {
                        //return 300;

                        var documentHeight = $(document).height();
                        var resultsHeight = $('.module-landing-content').height();
                        var bottom = documentHeight - resultsHeight - offsetTop;
                        return bottom;
                    }
                }
            });
        }
    });

});