define("carousel", function(require) {
    'use strict';

    /**
     * Sets up the module
     * @param settings The settings object from the module displaying the carousel
     */
    var setup = function(settings) {
        carousel(settings);
    };


    /**
     * Initializes the normalization of the carousel items
     * @param settings The settings object from the module displaying the carousel
     */
    var carousel = function(settings) {
        $('.owl-carousel-1').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                }
            }
        });

        $('.owl-carousel-2').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                },
                1000: {
                    items: 2
                }
            }
        });

        $('.owl-carousel-3').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                },
                550: {
                    items: 2
                },
                1600: {
                    items: 3
                }
            }
        });

        $('.owl-carousel-4').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                },
                650: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }
        });

        $('.owl-carousel-5').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                768: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1200: {
                    items: 5
                }
            }
        });

        $('.owl-carousel-6').owlCarousel({
            margin: 14,
            nav: false,
            dots:false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                768: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1200: {
                    items: 5
                },
                1400: {
                    items: 6
                }
            }
        });

        // Custom pagination
        // All carousels should have the ".owl-carousel-wrapper" class on carousel wrapper element

        $('.customPrevBtn').click(function() {
            //console.log('clicked prev');
            $(this).parents('.owl-carousel-wrapper').find('.owl-carousel').trigger('prev.owl.carousel');
        });
        $('.customNextBtn').click(function() {
            //console.log('clicked next');
            $(this).parents('.owl-carousel-wrapper').find('.owl-carousel').trigger('next.owl.carousel');
        });

        // Custom dots
        // All carousels should have the ".owl-carousel-wrapper" class on carousel wrapper element

        $('.owl-carousel-wrapper').find('.owl-dot:first-child').addClass('active');
        $('.owl-dot').click(function () {
            $(this).parents('.owl-carousel-wrapper').find('.owl-dot').removeClass('active');
            $(this).addClass('active');
            $(this).parents('.owl-carousel-wrapper').find('.owl-carousel').trigger('to.owl.carousel', [$(this).index(), 300]);
        });

        $('.owl-carousel').on('changed.owl.carousel', function(e) {
            var index = e.item.index;
            console.log('index', index);
            var dots = $(this).parents().find('.owl-dots .owl-dot');
            if(dots.length) {
                dots.each(function (){
                    if ($(this).index() === index) {
                        $(this).addClass('active');
                    } else {
                        $(this).removeClass('active');
                    }
                });
            }
        });

        // Owl carousel switcher
        $('.owl-carousel-switcher-navi > div').click(function (){
            $('.owl-carousel-switcher-navi > div').removeClass('active');
            $(this).addClass('active');
            var id = $(this).attr('data-id');
            $(this).parents('.owl-carousel-switcher').find('.owl-carousel-wrapper').each(function (){
                if($(this).attr('data-id') == id) {
                    $(this).addClass('active');
                } else {
                    $(this).removeClass('active');
                }
            });
        })
    };


    return {
        setup: setup
    };

});