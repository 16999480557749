define("ya-core", function(require) {
    'use strict';

    require('select2');
    require('owl.carousel');
    require('./navigation.js');
    require('./pages/index.js');
    require('./pages/search.js');
    require('./pages/wishlist.js');
    require('./pages/details.js');
    require('./pages/destination.js');
    require('./pages/enquiry.js');
    require('./pages/mediacenter.js');
    require('./pages/landing.js');
    require('../../node_modules/bootstrap/js/tooltip.js');


    $(function () {

        $('[data-toggle="tooltip"]').tooltip({container: 'body'});

        // Prevent jumping to the top of the page when clicking a link
        // that points to an empty fragment identifier
        $('a[href="#"]').click(function (e) {
            e.preventDefault();
        });

        $.fn.select2.defaults.set("theme", "bootstrap");
        if ($(window).width() > 768) {
            $(".select2").select2({width: "resolve"});
        }
    })

});