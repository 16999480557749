define(function(require) {
    'use strict';

    require('../modules/latest-bookings');

    var addYachtToWishlist = require('../modules/effects/addYachtToWishlist');
    var carousel =  require('../modules/components/owl-carousel');


    var settings = {
        selector: {
            addYachtToWishlist: '.yachtcharter-search-result .add-to-wishlist',
        }
    };

    $(function() {
        addYachtToWishlist.setup(settings);
        carousel.setup(settings);
    });

});