/**
 * Create bootstrap accordions without requiring ids.
 *
 * More information:
 * http://stackoverflow.com/questions/18767485/can-i-specify-a-relative-data-target-for-collapse-accordion-in-bootstrap-3-whi
 *
 */

define('accordion',function(require) {
    'use strict';

    //var $ = require('jquery');
    //require('bootstrap/collapse');
    //require('bootstrap/transition');
    //require('bootstrap/js/transition');

    $(function(){
        $('.panel-collapse').collapse({toggle: false});
        $('body').on('click', '[data-toggle=collapse-next]', function (e) {
            e.preventDefault();
            $(this).toggleClass('collapsed');

            // Try to close all of the collapse areas first
            var parent_id = $(this).data('parent');
            $(parent_id + ' > .panel > .panel-collapse').collapse('hide');

            // ...then open just the one we want
            var $target = $(this).parentsUntil('.panel-group', '.panel').children('.panel-collapse');
            $target.collapse('toggle');
        });
    });
});