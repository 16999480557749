define("navigation", function(require) {
    'use strict';

    //var $ = require('jquery');

    var settings = {
        selector: {
            navbarDefault: '.navbar-default',
            trustBar: '.yachtcharter-trust-bar',
            navBarSlim: '.navbar-slim.navbar-static',
            stickySidebar: '.sticky-sidebar'
        }
    };

    // require('bootstrap/dropdown');
    // require('bootstrap/collapse');

    var isHidden = false;

    var hideTrustBar = function() {
        var scrollTop = $(window).scrollTop();
        var $navbarDefault = $(settings.selector.navbarDefault);
        var $stickySidebar = $(settings.selector.stickySidebar);

        $(window).off('scroll', hideTrustBar);

        if(scrollTop > 100 && isHidden === false) {
            $navbarDefault.addClass('trustbar-collapsed')
            $stickySidebar.addClass('jumped')
            isHidden = true;
        } else if (scrollTop < 100 && isHidden) {
            $navbarDefault.removeClass('trustbar-collapsed')
            $stickySidebar.removeClass('jumped')
            isHidden = false;
        }

        setTimeout(function() {
            $(window).scroll(hideTrustBar);
        }, 10);
    };

    $(window).scroll(hideTrustBar);

});