define("search", function(require) {
    'use strict';
    
    //var $ = require('jquery');
    require('../modules/components/bootstrap-accordion-without-ids');

    //var addYachtToEnquiry = require('../modules/effects/addYachtToEnquiry');

    var addYachtToWishlist = require('../modules/effects/addYachtToWishlist');
    var yachtGrid = require('../modules/components/yacht-grid');
    
    //require('bootstrap/transition');
    
    var settings = {
      selector: {
          includedServices: '.module-yacht-search .yachtcharter-search-result .included-services-detail-link',
          priceDetails: '.module-yacht-search .price-details',
          //addYachtToEnquiry: '.yachtcharter-search-result .add-yacht-to-enquiry',
          addYachtToWishlist: '.yachtcharter-search-result .add-to-wishlist',
          searchResult: '.yachtcharter-search-result',
          totalPrice: '.yachtcharter-search-result .total-price',
          totalPriceElement: '.total-price'
      }
    };

    

    $(function() {
        //console.log('search page');
        //addYachtToEnquiry.setup(settings);
        addYachtToWishlist.setup(settings);

        $(settings.selector.includedServices).each(function() {
            $(this).popover({
                content: $(this).closest('.yachtcharter-search-result').find('.included-services-detail').html(),
                placement: 'auto top',
                html: true,
                trigger: 'hover',
                container: 'body'
            });
        });

        function toggleFilterOnResizeToDesktop() {
            var filtersPanel = $('#filter-options');
            var w = $(window).width();
            if(w >= 992) {
                if(filtersPanel.hasClass('collapse') || filtersPanel.hasClass('in')) {
                    filtersPanel.removeClass('collapse in').removeAttr('style');
                }
            }
        }

        $(window).resize(function() {
            toggleFilterOnResizeToDesktop();
        });

        yachtGrid.setup(settings);
    });
    
    

});